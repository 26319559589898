<script>
  import SvgIcon from "@/assets/icon/index.vue";
  import info from "@/assets/img/info";
  export default {
    name: "FAQsComputer",
    components: {SvgIcon},
    data() {
      return {
        info
      }
    },
    created() {
    },
    computed() {
    }
  }
</script>

<template>
  <div class="faq-main">
    <div class="info-download" v-for="(item,index) in info" :key="index">
      <div class="info-div">
        <img class="img-download" :src="item.img">
        <span class="span-download">{{ item.title }}</span>
      </div>
      <div class="download-div">
        <svg-icon icon-class="word" style="font-size: 60rem;color: #8989dc"></svg-icon>
        <span class="span-download">{{ item.faqname }}</span>
        <a :href="item.faqflile" :download="item.faqname">
          <div class="download-button">Download</div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faq-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50rem;
}

.info-download {
  display: flex;
  height: 100rem;
  margin-top: 20rem;
}

.info-div {
  height: 100%;
  display: flex;
  align-items: center;
}

.info-div span {
  width: 250rem;
}

.img-download {
  width: 80rem;
}

.span-download {
  margin: 0 20rem;
}

.download-div {
  display: flex;
  height: 100%;
  align-items: center;
}

.download-div span {
  width: 700rem;
}

.download-button {
  width: 200rem;
  height: 50rem;
  color: white;
  line-height: 50rem;
  text-align: center;
  border-radius: 5rem;
  background-color: #3A3A3E;
}

@media (max-width: 600px) {

}
</style>