<script>
import SvgIcon from "@/assets/icon/index.vue";
import brandsinfo from "@/assets/img/brandsinfo";
import Clipboard from 'clipboard'
export default {
  name: "ContactEmail",
  data(){
    return {
      brandsinfo
    }
  },
  created() {
    this.brandsinfo.contact_details.forEach((item,index)=>{
      item.static = false
      item.id = "copy" + index
    })
  },
  methods: {
    copy_text(item,id){
      if (item.name === "Facebook" || item.name === "Time"){
        return;
      }
      var clipBoard = new Clipboard('#' + id)
      clipBoard.on('success', function() {
        clipBoard.destroy()
        clipBoard = new Clipboard('#' + id)
        item.static = true
        setTimeout(()=>{
          item.static = false
        },2000)
      })
      clipBoard.on('error', function() {
        console.log('Copy failed, please copy manually')
      })
    }
  },
  components: {SvgIcon}
}
</script>

<template>
  <div class="contact-us-email">
    <span class="contact-title">CAN'T FIND WHAT YOU NEED?</span>
    <span class="contact-message">Contact our Customer Service Team</span>
    <div class="contact-out">
      <div class="contact-input">
        <input type="text" placeholder="NAME">
        <input type="text" placeholder="EMAIL">
        <input type="text" placeholder="ORDER ID">
        <textarea cols="10" placeholder="MESSAGE"></textarea>
      </div>
      <div class="contact-icon">
        <div v-for="(item,index) in brandsinfo.contact_details" :key="index" class="contact-info-icon">
          <a :href="item.link?item.link:'javascript:;'"
             :id="item.id"
             :target="item.link?'_blank':''"
             :data-clipboard-text="item.name ==='Call'?item.text[1]+item.text[2]+item.text[3]:item.text"
             @click="copy_text(item,item.id)">
            <svg-icon :icon-class="item.name"></svg-icon>
            <span>{{item.name ==="Call"?`${item.text[0]} (${item.text[1]})-${item.text[2]}-${item.text[3]}`:item.text}}</span>
          </a>
          <svg-icon
              v-if="item.name !== 'Facebook' && item.name !== 'Time'"
              :icon-class="item.static?'CopyCheck':'Copy'"
              :id="`svg-${item.id}`"
              :data-clipboard-text="item.name ==='Call'?item.text[1]+item.text[2]+item.text[3]:item.text"
              :style="`color: ${item.static?'#7bd17b':'#6E5A5C'};cursor: pointer;font-size: 35rem;`"
              @click="copy_text(item,`svg-${item.id}`)"
          ></svg-icon>
        </div>
      </div>
      <div class="button-send">SEND MESSAGE</div>
    </div>
  </div>
</template>

<style scoped>
.contact-us-email{
  margin-top: 70rem;
  width: 65%;
  background-color: #F9F3F1;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10rem;
  padding-bottom: 120rem;
  box-shadow: 0 0 7rem 2rem rgb(0,0,0,.3);
}
.contact-title{
  font-family: "Aleo", "sans-serif";
  font-size: 50rem;
  margin-top: 50rem;
}
.contact-message{
  color: #b59da1;
  margin-bottom: 50rem;
}
.contact-out{
  display: flex;
  position: relative;
}
.contact-input{
  display: flex;
  flex-direction: column;
}
.contact-input > * {
  border: #b59da1 solid 2rem;
  font-size: 27rem;
  border-radius: 7rem;
  height: 60rem;
  width: 550rem;
  margin: 5rem 0;
  padding: 10rem;
  box-sizing: border-box;
  resize: none;
}
.contact-input textarea{
  height: 250rem;
}
.contact-icon{
  margin-left: 20rem;
}
.contact-info-icon{
  display: flex;
  align-items: center;
  margin-bottom: 49rem;
}
.contact-info-icon a{
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.contact-info-icon a svg{
  font-size: 50rem;
  color: #6E5A5C;
  width: 120rem;
}
.contact-icon span{
  width: 550rem;
  color: #6E5A5C;
}
.button-send{
  position: absolute;
  background-color: #3A3A3E;
  color: white;
  padding: 20rem 50rem;
  left: 260rem;
  bottom: -60rem;
  border-radius: 5rem;
  user-select: none;
  cursor: pointer;
  box-shadow: 0 0 4rem 2rem rgb(0,0,0,.3);
}
.button-send:hover{
  background-color: #56565a;
}
.button-send:active{
  background-color: #3d3d40;
}
@media (max-width: 600px) {
  .contact-us-email{
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .contact-title{
    font-size: 35rem;
  }
  .contact-out{
    flex-direction: column;
  }
  .contact-input{
    width: 100vw;
    align-items: center;
  }
  .contact-input > *{
    width: 500rem;
  }
  .contact-input textarea{
    height: 150rem;
  }
  .contact-icon{
    margin-top: 150rem;
  }
  .contact-icon span{
    width: 400rem;
  }
  .button-send{
    top: 400rem;
    left: 28vw;
    bottom: auto;
  }
}
</style>