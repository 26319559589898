function morphUpData(morph){
    this.morph = morph.morph
    this.img = morph.img
    this.title = morph.title
    this.prices = morph.prices
    this.price_unit = morph.price_unit
    this.is_stock = morph.is_stock
    this.amazonLink = morph.amazonLink
    this.img_all = [...morph.img,...this.img_common]
    this.img_aj = morph.aj
}

export default morphUpData