<template>
  <div class="selection-box">
    <div class="top-branding"><a href="/home"><img :src="brands_info.brandsimg"></a></div>
    <div class="navigator-title">
      <div v-for="(item,index) in top_bar" :key="index" style="cursor: pointer;">
        <router-link :to="item.path">
          <div :class='class_div_N(item.path)'>
            <!--            <svg-icon :icon-class="item.meta.icon" style="font-size: 30rem;"></svg-icon>-->
            <span>{{item.meta.title}}</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="navigator-mobile">
      <svg-icon icon-class="navigator" @click="mobile_top_click"></svg-icon>
      <div class="mobile-title-all" :style="`height: ${mobile_height}rem;`" @click="shields_mobile_click">
        <div v-for="(item,index) in top_bar" :key="index" style="cursor: pointer;">
          <router-link :to="item.path">
            <div class='title-mobile'>
              <span>{{item.meta.title}}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="external-link">
      <span>FOLLOW US</span>
      <a v-for="(item,index) in brands_info.social_account" :key="index" :title="item.name" :href="item.link" target="_blank">
        <svg-icon :icon-class="item.name" style="font-size: 50rem;"></svg-icon>
      </a>
    </div>
    <div class="shields-mobile" :style="`display: ${shields_mobile};`" @click="shields_mobile_click"></div>
  </div>
</template>

<script>
import brands_info from "@/assets/img/brandsinfo";
import SvgIcon from "@/assets/icon/index.vue";
export default {
  name: "TopBar",
  components: {SvgIcon},
  data(){
    return{
      brands_info,
      mobile_height: 0,
      shields_mobile: "none"
    }
  },
  props:['top_bar'],
  created() {
  },
  methods: {
    mobile_top_click(){
      this.mobile_height = 370
      this.shields_mobile = "block"
    },
    shields_mobile_click(){
      this.mobile_height = 0
      this.shields_mobile = "none"
    }
  },
  computed: {
    class_div_N(){
      return (path) => {
        return 'selection-box-div ' + ( path.split('/')[1]===this.$route.path.split('/')[1]? 'selection-box-xz' : '' )
      }
    }
  }
}
</script>

<style scoped>
.selection-box{
  width: 90%;
  font-size: 25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-branding img{
  width: 200rem;
  object-fit: contain;
}
.navigator-title{
  display: flex;
}
.selection-box-div{
  width: 100%;
  height: 84rem;
  display: flex;
  align-items: center;
  padding: 0 10rem;
  box-sizing: border-box;
}
.selection-box-div span{
  margin-left: 10rem;
  user-select:none;
}
.selection-box-xz span{
  border-bottom: #b59da1 1rem solid;
  color: #8cbd89;
}
.external-link{
  display: flex;
  align-items: center;
}
.external-link span{
  color: rgb(171,170,171);
  margin: 10rem 10rem;
}
.external-link svg{
  margin: 10rem 10rem;
}
.navigator-mobile{
  display: none;
}
.shields-mobile{
  display: none;
}
@media (max-width: 600px) {
  .selection-box{
    margin-top: 30rem;
  }
  .navigator-title {
    display: none;
  }
  .external-link{
    display: none;
  }
  .navigator-mobile{
    display: block;
    position: relative;
  }
  .navigator-mobile svg{
    font-size: 50rem;
  }
  .mobile-title-all{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 50rem;
    z-index: 10;
    transition: height .2s;
    overflow: hidden;
    border-radius: 10rem;
  }
  .title-mobile{
    padding: 5rem 10rem;
    width: 220rem;
    background-color: white;
    border: #f6f2f2 1rem solid;
  }
  .shields-mobile{
    position: absolute;
    top: 0;left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
  }
}
</style>