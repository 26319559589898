import catalogMain from "@/components/Catalog/CatalogMain.vue";
import infoAll from "@/assets/img/info";
const catalog = []
infoAll.forEach(item=>{
    catalog.push({
        path: item.path,
        name: item.name,
        component: catalogMain,
        meta: { title:item.title, morph: item.morphjs, img_all: item.img_all },
    })
})

export default catalog