const import_rs = (r) => r.keys().map(r);
const import_file_img = {
    calming: import_rs((()=>{
        let imgAll = []
        try {
            imgAll = require.context(`@/assets/img/morphjs/img/calming/common`, false, /\.(png|jpe?g|gif|svg)$/i)
        }catch (e){
            console.log(e)
        }
        return imgAll
    })()),
    allergy: import_rs((()=>{
        let imgAll = []
        try {
            imgAll = require.context(`@/assets/img/morphjs/img/allergy/common`, false, /\.(png|jpe?g|gif|svg)$/i)
        }catch (e){
            console.log(e)
        }
        return imgAll
    })()),
    teethcleaning: import_rs((()=>{
        let imgAll = []
        try {
            imgAll = require.context(`@/assets/img/morphjs/img/teethcleaning/common`, false, /\.(png|jpe?g|gif|svg)$/i)
        }catch (e){
            console.log(e)
        }
        return imgAll
    })()),
    hipjoint: import_rs((()=>{
        let imgAll = []
        try {
            imgAll = require.context(`@/assets/img/morphjs/img/hipjoint/common`, false, /\.(png|jpe?g|gif|svg)$/i)
        }catch (e){
            console.log(e)
        }
        return imgAll
    })()),
    probiotics: import_rs((()=>{
        let imgAll = []
        try {
            imgAll = require.context(`@/assets/img/morphjs/img/probiotics/common`, false, /\.(png|jpe?g|gif|svg)$/i)
        }catch (e){
            console.log(e)
        }
        return imgAll
    })()),
}

export default import_file_img;