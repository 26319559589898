<template>
<div class="layout">
  <div style="position: absolute;left: 0;top: 0;opacity: 0;transition: opacity 1s">Copy Success</div>
  <div class="top-message">
    <svg-icon icon-class="birthday" style="font-size: 50rem;"></svg-icon>
    <span style="margin-left: 30rem">The Perfect Gift, For Every Occasion. Got Questions?</span>
  </div>
  <TopBar :top_bar="top_bar"></TopBar>
  <div class="view-html">
    <router-view :key="view_key[$route.fullPath]"></router-view>
  </div>
  <div class="layout-bottom">
    <BottomBar></BottomBar>
  </div>
</div>
</template>

<script>
import TopBar from "@/layout/TopBar"
import dynamics from "@/router/routes/dynamics.js";
import BottomBar from "@/layout/BottomBar.vue";
export default {
  name: "LayoutIndex",
  components: {
    TopBar,
    BottomBar
  },
  data(){
    return{
      top_bar:dynamics,
      view_key:{}
    }
  },
  created() {
    this.top_bar.forEach(item => {
      this.view_key[item.path] = item.name;
    })
  }
}
</script>

<style scoped>
.layout{
  width: 100%;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  font-size: 25rem;
}
.top-message{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  height: 125rem;
  background-color: rgb(34,191,139);
}
.layout-bottom{
  width: 100%;
  height: 300rem;
}
.view-html{
  width: 100%;
}
</style>