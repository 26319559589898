const import_rs = (r) => r.keys().map(r);
const AEG05_aj = import_rs(require.context(`@/assets/img/morphjs/img/probiotics/AEG05_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEG05_LB = import_rs(require.context(`@/assets/img/morphjs/img/probiotics/AEG05_LB`, false, /\.(png|jpe?g|gif|svg)$/i))
const probiotics_morph = [
    {
        morph: "LAMB-150CHEWS",
        img : [require("./img/probiotics/AEG05.jpg")],
        title: "Probiotics for Dogs 150 Count Soft Chews Advanced Dog Probiotics with Prebiotics 6 Digestive Enzymes Promotes Gut Health Lamb Flavor",
        prices: 19.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DFWDWZL2",
        aj: AEG05_aj,
        lb: AEG05_LB
    }
]
export default probiotics_morph