<script>
import FAQsComputer from "@/components/FAQs/FAQsComputer.vue";
import FAQsMobile from "@/components/FAQs/FAQsMobile.vue";
export default {
  name: "FAQsMain",
  components: {
    FAQsComputer,
    FAQsMobile
  },
  data(){
    return {
    }
  },
  created() {
  },
  computed(){
  }
}
</script>

<template>
<div>
  <div class="FAQs-Computer">
    <FAQsComputer></FAQsComputer>
  </div>
  <div class="FAQs-Mobile">
    <FAQsMobile></FAQsMobile>
  </div>
</div>
</template>

<style scoped>
.FAQs-Mobile{
  display: none;
}
@media (max-width: 600px) {
  .FAQs-Computer{
    display: none;
  }
  .FAQs-Mobile{
    display: block;
  }
}
</style>