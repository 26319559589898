const import_rs = (r) => r.keys().map(r);
const AEH0134_img = import_rs(require.context(`@/assets/img/morphjs/img/hipjoint/AEH0134_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEH06_img = import_rs(require.context(`@/assets/img/morphjs/img/hipjoint/AEH06_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const hipjoint_morph = [
    {
        morph: "CHICKEN-120CHEWS",
        img : [require("./img/hipjoint/AEH01.jpg")],
        title: "Joint Supplement for Dogs, Glucosamine Chondroitin for Dogs Joint Pain Relief, MSM - Advanced Mobility Chews Reduce Inflammation, Hip & Joint Care - Chicken Flavor - 120 Ct",
        prices: 17.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0CWXKZ6PG",
        aj: AEH0134_img
    },
    {
        morph: "CHICKEN-90CHEWS",
        img : [require("./img/hipjoint/AEH03.jpg")],
        title: "Joint Supplement for Dogs, Glucosamine Chondroitin for Dogs Joint Pain Relief, MSM - Advanced Mobility Chews Reduce Inflammation, Hip & Joint Care - Chicken Flavor - 90 Ct",
        prices: 13.39,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0CWXX55NJ",
        aj: AEH0134_img
    },
    {
        morph: "LAMB-120CHEWS",
        img : [require("./img/hipjoint/AEH04.jpg")],
        title: "Joint Supplement for Dogs Glucosamine for Dogs Heart Shaped Chews Hip & Joint Supplement for Dogs 120 Count Lamb Flavor",
        prices: 17.79,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0D9858BWV",
        aj: AEH0134_img
    },
    {
        morph: "CHICKEN-150CHEWS",
        img : [require("./img/hipjoint/AEH06.jpg")],
        title: "Glucosamine for Dogs 150 Count Joint Supplement for Dogs with Glucosamine Chondroitin Hip & Joint Care Supplement Chicken Flavor",
        prices: 21.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DFW8Z8YM",
        aj: AEH06_img
    },
]
export default hipjoint_morph