<script>
import CatalogComputer from "@/components/Catalog/CatalogComputer.vue";
import CatalogMobile from "@/components/Catalog/CatalogMobile.vue";
export default {
  name: "CatalogMain",
  components: {
    CatalogComputer,
    CatalogMobile
  }
}
</script>

<template>
<div>
  <CatalogComputer class="CatalogComputer"></CatalogComputer>
  <CatalogMobile class="CatalogMobile"></CatalogMobile>
</div>
</template>

<style scoped>
.CatalogMobile{
  display: none;
}
@media (max-width: 600px) {
  .CatalogComputer{
    display: none;
  }
  .CatalogMobile{
    display: block;
  }
}
</style>