<script>
import infoAll from "@/assets/img/info";
import SvgIcon from "@/assets/icon/index.vue";
export default {
  name: "HomeCarousel",
  components: {SvgIcon},
  data(){
    return{
      img_list: [],
      // carousel_left: -1040,
      carousel_left: 0,
      carousel_length: 0,
      trans_str: "left 0.3s"
    }
  },
  created() {
    this.carousel_length = infoAll.length
    this.img_list = infoAll
    // this.img_list = [...infoAll.slice(this.carousel_length-2,this.carousel_length),...infoAll,...infoAll.slice(0,2)]
  },
  methods: {
    left_arrow_h(){
      if(this.carousel_left >= 0){
        this.trans_str = "none"
        this.carousel_left = -520*this.carousel_length
        setTimeout(() => {
          this.trans_str = "left 0.3s"
          this.carousel_left = -520*(this.carousel_length-1)
        })
      }else {
        this.carousel_left += 520
      }
    },
    right_arrow_h(){
      if (this.carousel_left <= -520*(this.carousel_length+1)){
        this.trans_str = "none"
        this.carousel_left = -520
        setTimeout(() => {
          this.trans_str = "left 0.3s"
          this.carousel_left = -1040
        })
      }else {
        this.carousel_left -= 520
      }
    },
    left_arrow(){
      if (this.carousel_left < 0){
        this.carousel_left += 520
      }
    },
    right_arrow(){
      if (this.carousel_left > -520 * (this.carousel_length-3)){
        this.carousel_left -= 520
      }
    },
    img_link(path){
      this.$router.push({path:path})
    }
  }
}
</script>

<template>
<div class="home-carousel">
  <div class="carousel-hidden">
    <div class="carousel-div" :style="`left: ${carousel_left}rem;transition: ${trans_str};`">
      <div class="img-div" v-for="(item,index) in img_list" :key="index">
        <img :src="item.img" @click="img_link(item.path)">
        <div class="img-div-span">
          <svg-icon icon-class="dogLeg"></svg-icon>
          <span>{{item.imgname}}</span>
          <svg-icon icon-class="dogLeg"></svg-icon>
        </div>
        <div class="carouse-svg svg-dog-t">
          <svg-icon icon-class="dog"></svg-icon>
        </div>
        <div v-for="(item,index) in [1,2,3,4,5,6,7,8]" :class="'carouse-svg svg-div'+item" :key="index">
          <svg-icon icon-class="bone" style="transform: rotateZ(-43deg);color: rgb(245,218,223)"></svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="left-arrow" @click="left_arrow"><svg-icon icon-class="left" style="font-size: 80rem;"></svg-icon></div>
  <div class="right-arrow" @click="right_arrow"><svg-icon icon-class="right" style="font-size: 80rem;"></svg-icon></div>
</div>
</template>

<style scoped>
.home-carousel{
  position: relative;
  width: 1560rem;
  height: 500rem;
  margin-top: 100rem;
  user-select: none;
}
.carousel-hidden{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.carousel-div{
  width: 100%;
  position: absolute;
  display: flex;
}
.left-arrow{
  color: rgb(221, 204, 146);
  position: absolute;
  top: 170rem;
  left: -80rem;
  cursor: pointer;
}
.left-arrow:hover{
  color: rgb(170, 159, 114);
}
.right-arrow{
  color: rgb(221, 204, 146);
  position: absolute;
  top: 170rem;
  right: -85rem;
  cursor: pointer;
}
.right-arrow:hover{
  color: rgb(170, 159, 114);
}
.img-div{
  width: 520rem;
  height: 450rem;
  flex:0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.img-div img{
  width: 250rem;
  height: 250rem;
  margin-top: 45rem;
  object-fit: contain;
  border-radius: 30rem;
  cursor: pointer;
  z-index: 1;
}
.svg-dog-t{
  top: -19rem;
  color: rgb(221, 204, 146);
}
.img-div img:hover ~ .svg-dog-t{
  color: rgb(170, 159, 114);
}
.img-div-span{
  position: absolute;
  bottom: 55rem;
  font-size: 35rem;
  user-select: none;
  z-index: 1;
}
.img-div-span span{
  margin: 10rem;
  font-size: 35rem;
}
.img-div-span svg{
  color: rgb(239, 218, 231);
}
.carouse-svg{
  position: absolute;
  font-size: 50rem;
}
.svg-div1{
  right: 70rem;
  top: 30rem;
}
.svg-div2{
  left: 70rem;
  top: 30rem;
}
.svg-div3{
  right: 70rem;
  top: 95rem;
}
.svg-div4{
  left: 70rem;
  top: 95rem;
}
.svg-div5{
  right: 70rem;
  top: 160rem;
}
.svg-div6{
  left: 70rem;
  top: 160rem;
}
.svg-div7{
  right: 70rem;
  top: 225rem;
}
.svg-div8{
  left: 70rem;
  top: 225rem;
}
@media (max-width: 600px){
  .left-arrow,.right-arrow{
    display: none;
  }
  .home-carousel{
    width: 520rem;
  }
}
</style>