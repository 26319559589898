<script>
import ContactEmail from "@/components/ContactUS/ContactEmail.vue";
import ContactAboutUS from "@/components/ContactUS/ContactAboutUS.vue"
export default {
  name: "ContactUSMain",
  data(){
    return {
    }
  },
  components:{
    ContactEmail,
    ContactAboutUS
  }
}
</script>

<template>
<div class="contact-us">
  <ContactEmail></ContactEmail>
  <ContactAboutUS></ContactAboutUS>
</div>
</template>

<style scoped>
.contact-us{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>