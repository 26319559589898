const import_rs = (r) => r.keys().map(r);
const AED023_aj = import_rs(require.context(`@/assets/img/morphjs/img/teethcleaning/AED023_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const AED023_LB = import_rs(require.context(`@/assets/img/morphjs/img/teethcleaning/AED023_LB`, false, /\.(png|jpe?g|gif|svg)$/i))
const teethcleaning_morph = [
    {
        morph: "90g",
        img : [require("./img/teethcleaning/AED02.jpg"),require("./img/teethcleaning/AED02-2.jpg")],
        title: "Dog Teeth Cleaning Powder - Dog Plaque and Tartar Remover - Dental Powder for Dogs Teeth with Activated Carbon- Dog Breath Freshener to Help Dog Dental Care",
        prices: 18.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0CZ9KBVCN",
        aj: AED023_aj,
        lb: AED023_LB
    },
    {
        morph: "180g",
        img : [require("./img/teethcleaning/AED03.jpg"),require("./img/teethcleaning/AED03-2.jpg")],
        title: "Dog Teeth Cleaning Powder,Herbal Dog Dental with Breath Freshener,Plaque Remover for Teeth Dog Targets Tartar & Bad Breath,Healthy Mouth for Dogs",
        prices: 29.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0CZ9CP2NL",
        aj: AED023_aj,
        lb: AED023_LB
    }
]
export default teethcleaning_morph