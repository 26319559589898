const import_rs = (r) => r.keys().map(r);
const AEA0123_aj = import_rs(require.context(`@/assets/img/morphjs/img/allergy/AEA0123_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEA0123_lb = import_rs(require.context(`@/assets/img/morphjs/img/allergy/AEA0123_LB`, false, /\.(png|jpe?g|gif|svg)$/i))
const allergy_morph = [
    {
        morph: "CHICKEN-120CHEWS",
        img : [require("./img/allergy/AEA01.jpg")],
        title: "Dog Allergy Relief Chews, Omega 3 Skin & Coat Supplement with Colostrum, Probiotics, Dog Itching Skin Relief, Dry Skin, Itchy Paws - Seasonal Allergies & Hot Spots Support Treats - Chicken Flavor",
        prices: 17.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DDPBP298",
        aj:AEA0123_aj,
        lb:AEA0123_lb
    },
    {
        morph: "SALMON-120CHEWS",
        img : [require("./img/allergy/AEA02.jpg")],
        title: "Dog Allergy Relief Chews - Anti Itch Immune Treats with Probiotics and Colostrum for Dry Skin, Hot Spots, Licking, Seasonal Allergies - Dog Itching Skin Relief -Omega 3 Fish Oil Skin & Coat Supplement",
        prices: 17.99,
        price_unit: "USD",
        is_stock: false,
        amazonLink: "https://www.amazon.com/dp/B0DCJXJ23J",
        aj:AEA0123_aj,
        lb:AEA0123_lb
    },
    {
        morph: "LAMB-120CHEWS",
        img : [require("./img/allergy/AEA03.jpg")],
        title: "Dog Allergy Relief Chews, Omega 3 Fish Oil Skin & Coat Supplement with Colostrum, Probiotics, Dog Itching Skin Relief, Dry Skin, Itchy Paws - Seasonal Allergies&Hot Spots Support Treats - Lamb Flavor",
        prices: 17.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DDPKMC1N",
        aj:AEA0123_aj,
        lb:AEA0123_lb
    },
]
export default allergy_morph