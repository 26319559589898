<script>
import brands_info from "@/assets/img/brandsinfo";
export default {
  name: "BottomBar",
  data(){
    return {
      brands_info,
      clause:[
        {
          title:"Terms of Service",
          path:""
        },
        {
          title:"Return and Refund",
          path:""
        },
        {
          title:"Shipping Policy",
          path:""
        },
        {
          title:"Privacy Policy",
          path:""
        }
      ]
    }
  },
  computed: {
    info_value(){
      return (key)=>{
        let value = ""
        for(var item=0;item < this.brands_info.contact_details.length;item++){
          if(this.brands_info.contact_details[item].name === key){
            value = this.brands_info.contact_details[item].text
            break
          }
        }
        return value
      }
    }
  }
}
</script>

<template>
<div class="bottom-bar">
  <div class="bottom-title">
    <a href="/home" class="bottom-coleaze">
      <img style="width: 200rem;object-fit: contain;user-select: none" :src="brands_info.brandsimg">
    </a>
    <span class="bottom-call">{{`${info_value("Call")[0]} (${info_value("Call")[1]})-${info_value("Call")[2]}-${info_value("Call")[3]}`}}</span>
    <span class="bottom-email">{{ info_value("Email") }}</span>
  </div>
  <div class="bottom-clause">
    <div class="bottom-2024">© 2024 ColEaze.com Inc. or its affiliates. All rights reserved.</div>
    <div class="bottom-span">
      <div></div>
    </div>
    <div>
      <a v-for="(item,index) in brands_info.social_account" :key="index" :title="item.name" :href="item.link" target="_blank">
        <svg-icon :icon-class="item.name" style="font-size: 30rem;margin: 0 10rem"></svg-icon>
      </a>
    </div>
  </div>
  <div class="bottom-contexts"></div>
</div>
</template>

<style scoped>
.bottom-bar{
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.bottom-title{
  position: relative;
  width: 70%;
  height: 150rem;
  background-color: white;
  border-radius: 150rem;
  display: flex;
  justify-content: center;
}
.bottom-coleaze{
  height: 50rem;
  margin-top: 40rem;
}
.bottom-call{
  position: absolute;
  bottom: 50rem;
  left: 70rem;
}
.bottom-email{
  position: absolute;
  bottom: 50rem;
  right: 70rem;
}
.bottom-clause{
  display: flex;
  width: 70%;
  margin-top: 40rem;
  color: white;
  justify-content: space-between;
}
.bottom-contexts{
  width: 100%;
  height: 180rem;
  position: absolute;
  background-color: rgb(34,191,139);
  bottom: 0;
  z-index: -1;
}
.bottom-span{
  display: flex;
  user-select:none;
}
@media (max-width: 600px) {
  .bottom-bar{
    margin-top: 138rem;
  }
  .bottom-call {
    display: none;
  }
  .bottom-email{
    display: none;
  }
  .bottom-2024{
    display: none;
  }
  .bottom-span{
    display: none;
  }
  .bottom-clause{
    width: 100%;
    justify-content: center;
    margin: 20rem 0;
  }
  .bottom-contexts{
    height: 140rem;
  }
  .bottom-coleaze{
    margin-top: 50rem;
  }
}
</style>