const brands_info = {
    "brandsname": "ColEaze",
    "brandsimg": require("./brands/brands.png"),
    "social_account":[
        {
            name: "Facebook",
            link: "https://www.facebook.com/profile.php?id=61569347260539"
        },
        {
            name: "Instagram",
            link: "https://instagram.com/coleaze_daily_life?r=nametag"
        },
        {
            name: "Twitter",
            link: "https://x.com/chenpenny666"
        },
        {
            name: "Youtube",
            link: "https://www.youtube.com/@ColEase"
        }
    ],
    "contact_details":[
        {
            name: "Email",
            text: "Support@coleaze.com"
        },
        {
            name: "Call",
            text: ["+1","208","280","0473"]
        },
        {
            name: "Map",
            text: "9000 East Nichols Avenue, AvenueSuite 202, Centennial, Colorado, UnitedStates"
        },
        {
            name: "Facebook",
            text: "Find us on Facebook",
            link: "https://www.facebook.com/profile.php?id=61569347260539"
        },
        {
            name: "Time",
            text: "Monday-Friday,9am-5pm ET"
        }
    ]
}

export default brands_info