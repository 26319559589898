<script>
import HomeCarousel from "@/components/Home/HomeCarousel.vue";
export default {
  name: "HomeMain",
  components: {
    HomeCarousel
  }
}
</script>

<template>
  <div class="home-main">
    <HomeCarousel></HomeCarousel>
  </div>
</template>

<style scoped>
.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20rem;
}
</style>