import calming_morph from "@/assets/img/morphjs/calming";
import allergy_morph from "@/assets/img/morphjs/allergy";
import teethcleaning_morph from "@/assets/img/morphjs/teeth";
import probiotics_morph from "@/assets/img/morphjs/probiotics";
import import_file_img from "@/assets/img/import_file";
import hipjoint_morph from "@/assets/img/morphjs/hipjoint";

const infoAll = [
    {
        path: '/calming',
        name: 'CalmingMain',
        title:'CALMING',
        imgname:"Calming",
        img: require('./carousel/image_4.jpg'),
        morphjs: calming_morph,
        img_all: import_file_img.calming,
        faqname: "",
        faqflile: ""
    },
    {
        path: '/allergy',
        name: 'AllergyMain',
        title:'ALLERGY',
        imgname:"Allergy",
        img: require('./carousel/AEA01.jpg'),
        morphjs: allergy_morph,
        img_all: import_file_img.allergy,
        faqname: "",
        faqflile: ""
    },
    {
        path: '/teeth',
        name: 'TeethCleaningMain',
        title:'TEETH CLEANING',
        imgname:"TeethCleaning",
        img: require('./carousel/AED02.jpg'),
        morphjs: teethcleaning_morph,
        img_all: import_file_img.teethcleaning,
        faqname: "Frequently Asked Questions about Tooth Powder",
        faqflile: require("@/assets/file/FAQ About ColEaze Powers.docx")
    },
    {
        path: '/hipjoint',
        name: 'HipJointMain',
        title:'HIP & JOINT',
        imgname:"HIP & JOINT",
        img: require('./carousel/AEH01.jpg'),
        morphjs: hipjoint_morph,
        img_all: import_file_img.hipjoint,
        faqname: "",
        faqflile: ""
    },
    {
        path: '/probiotics',
        name: 'ProbioticsMain',
        title:'PROBIOTICS',
        imgname:"Probiotics",
        img: require('./carousel/AEG05.jpg'),
        morphjs: probiotics_morph,
        img_all: import_file_img.probiotics,
        faqname: "",
        faqflile: ""
    }
]
export default infoAll