import catalog from "@/router/routes/catalog";
import HomeMain from '@/components/Home/HomeMain.vue'
import FAQsMain from '@/components/FAQs/FAQsMain.vue'
import ContactUSMain from "@/components/ContactUS/ContactUSMain.vue";
const dynamics = [
    {
        path: '/home',
        name: 'HomeMain',
        component: HomeMain,
        meta: { title:'Home' },
    },
    ...catalog,
    {
        path: '/faqs',
        name: 'FAQsMain',
        component: FAQsMain,
        meta: { title:'FAQs' },
    },
    {
        path: '/contactus',
        name: 'ContactUSMain',
        component: ContactUSMain,
        meta: { title:'Contact US' },
    }
]
export default dynamics