const import_rs = (r) => r.keys().map(r);
const AEC01_img = import_rs(require.context(`@/assets/img/morphjs/img/calming/AEC01`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEC03_img = import_rs(require.context(`@/assets/img/morphjs/img/calming/AEC03`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEC05_img = import_rs(require.context(`@/assets/img/morphjs/img/calming/AEC05`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEC01_aj = import_rs(require.context(`@/assets/img/morphjs/img/calming/AEC01_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const AEC05_aj = import_rs(require.context(`@/assets/img/morphjs/img/calming/AEC05_A+`, false, /\.(png|jpe?g|gif|svg)$/i))
const calming_morph = [
    {
        morph: "CHICKEN-120CHEWS",
        img : [require("./img/calming/AEC01.jpg"), ...AEC01_img],
        title: "ColEaze Calming Chews for Dogs Calming Bites Anxiety Relief Stress Support Sleep Aid Relaxation Suitable for All Ages and Breeds Chicken Flavor 120 Count",
        prices: 17.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0D3CZH9FS",
        aj: AEC01_aj
    },
    {
        morph: "CHICKEN-90CHEWS",
        img : [require("./img/calming/AEC03.jpg"), ...AEC03_img],
        title: "ColEaze Calming Chews for Dogs Calming Bites Anxiety Relief Stress Support Sleep Aid Relaxation Suitable for All Ages and Breeds Chicken Flavor 90 Count",
        prices: 15.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DL58DJ5F",
        aj: "Provide your furry friend with effective anxiety relief with our Calming Chews for Dogs. Designed to combat stress and promote relaxation, these dog calming treats are enriched with natural ingredients like chamomile, valerian root, L-theanine, and melatonin for dogs, making them a safe and effective choice. With a veterinarian-recommended formula, these chicken-flavored calming dog treats are suitable for all breeds over 3 months old. Whether used daily or for specific events like vet visits or thunderstorms, these calming treats for dogs ensure comfort without compromising on taste or quality. Free from harmful additives, they are the perfect choice for your dog's well-being."
    },
    {
        morph: "CHICKEN-150CHEWS",
        img : [require("./img/calming/AEC05.jpg"),...AEC05_img],
        title: "Dog Calming Chews 150 Count Anxiety Relief for Dogs Sleeping Calming Aid Suitable for Reducing Anxiety Caused by Separation Barking Car Rides Chicken Flavor",
        prices: 19.99,
        price_unit: "USD",
        is_stock: true,
        amazonLink: "https://www.amazon.com/dp/B0DFW3TPYS",
        aj: AEC05_aj
    }
]
export default calming_morph