<script>
  import morphUpData from "@/utils/common/morphUpData"
  export default {
  name: "CatalogMobile",
  data(){
  return {
  morph: "",
  img : "",
  title: "",
  prices: "",
  price_unit: "",
  is_stock: true,
  amazonLink: "",
  morphs: [],
  img_common: [],
  img_all: [],
  img_aj: [],
  img_left: -450,
  trans_img: "left 0.3s"
}
},
  created() {
  this.morphs = this.$route.meta.morph
  this.img_common = this.$route.meta.img_all
  morphUpData.bind(this,this.morphs[0])()
},
  methods: {
  upData(data){
  this.trans_img = "none"
  this.img_left = -450
  setTimeout(() => {
  this.trans_img = "left 0.3s"
})
  morphUpData.bind(this,data)()
},
  arrow_left(){
  if (this.img_left >= 0){
  this.trans_img = "none"
  this.img_left = -450 * this.img_all.length
  setTimeout(() => {
  this.trans_img = "left 0.3s"
  this.img_left += 450
})
}else {
  this.img_left += 450
}
},
  arrow_right(){
  if(this.img_left <= -450 * (this.img_all.length + 1)){
  this.trans_img = "none"
  this.img_left = -450
  setTimeout(() => {
  this.trans_img = "left 0.3s"
  this.img_left -= 450
})
}else {
  this.img_left -= 450
}
},
  img_link_left(left){
  this.trans_img = "none"
  this.img_left = left
  setTimeout(() => {
  this.trans_img = "left 0.3s"
})
},
  detailed_information(hash){
  history.pushState(null, "", `#${hash}`);
  // 触发手动操作，比如滚动到目标位置
  const target = document.querySelector(`#${hash}`);
  if (target) {
  target.scrollIntoView();
}
}
},
  computed: {
  morph_button(){
  return (morph) => {
  return morph === this.morph? 'morph-button morph-button-xz' : 'morph-button morph-button-wxz'
}
},
  img_link(){
  return (left)=>{
  return (left === this.img_left || left === this.img_left - this.img_all.length*450 || left === this.img_left + this.img_all.length*450) ? 'link-img link-img-xz' : 'link-img'
}
}
}
}
</script>

<template>
  <div class="catalog-main">
    <div class="catalog-product">
      <div class="carousel-img">
        <div class="carousel-img-d">
          <div class="carousel-img-yd" :style="`left: ${img_left}rem;transition: ${trans_img};`">
            <img v-for="(item,index) in [img_common[img_common.length-1],...img_all,img[0]]" :src="item" :key="index">
          </div>
        </div>
        <div class="link-div">
          <img
              v-for="(item,index) in img_all"
              :class="img_link(-450*(index+1))"
              @click="img_link_left(-450*(index+1))"
              :style="`left: ${70*(index-img_all.length/2)-(img_link(-450*(index+1))==='link-img link-img-xz'?10:0)}rem;`"
              :src="item" :key="index">
        </div>
      </div>
      <div class="carousel-operator">
        <div class="carousel-info">
          <span class="info-prefix">PRODUCT SHORT DESCRIPTION</span>
          <span class="info-title">{{title}}</span>
          <span class="info-prices">${{prices.toFixed(2)}} {{price_unit}}</span>
        </div>
        <div class="carousel-morph">
          <span class="info-prefix">SELECT STYLE</span>
          <div class="morph-div">
            <div v-for="(item,index) in morphs" :key="index" @click="upData(item)" :class="morph_button(item.morph)">{{item.morph}}</div>
          </div>
        </div>
        <div class="carousel-bottom">
          <div class="stock-p">
            <div class="stock-state" :style="`background-color: ${is_stock ? 'green':'red'};`">
              <div></div>
              <span>{{is_stock ? "IN STOCK" : "NO STOCK"}}</span>
            </div>
            <span class="stock-sp" @click="detailed_information('imgaj')">VIEW FULL DETAILS</span>
          </div>
          <a :href="amazonLink" target="_blank">
            <div class="buy-button" >Buy with <span>Amazon</span></div>
          </a>
        </div>
      </div>
    </div>
    <div class="img-aj" id="imgaj">
      <div v-if="typeof img_aj === 'string'" class="description">
        <span style="font-size: 50rem;margin-bottom: 20rem">Product Description</span>
        <span>{{img_aj}}</span>
      </div>
      <div v-else>
        <img v-for="(item,index) in img_aj" :src="item" :key="index">
      </div>
    </div>
  </div>
</template>

<style scoped>
.catalog-main{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.catalog-product{
  margin-top: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.carousel-img{
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-img-d{
  width: 450rem;
  height: 450rem;
  margin-right: 10rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 0 4rem 2rem rgb(0,0,0,.2);
  overflow: hidden;
  position: relative;
}
.carousel-img-yd{
  display: flex;
  position: absolute;
}
.carousel-img-yd img{
  width: 450rem;
  height: 450rem;
  object-fit: contain;
}
.carousel-arrow{
  font-size: 70rem;
  color: aqua;
  cursor: pointer;
  position: absolute;
}
.link-div{
  margin-top: 30rem;
  position: relative;
  height: 120rem;
}
.link-img{
  width: 50rem;
  height: 50rem;
  top: 0;
  object-fit: contain;
  box-shadow: 0 0 4rem 2rem rgb(0,0,0,.2);
  cursor: pointer;
  position: absolute;
  transition: all .2s;
}
.link-img-xz{
  top: -10rem;
  width: 70rem;
  height: 70rem;
  box-shadow: 0 0 5rem 2rem rgb(0,0,0,.3);
}
.carousel-operator{
  display: flex;
  flex-direction: column;
  width: 700rem;
  box-sizing: border-box;
  padding: 20rem 60rem;
}
.carousel-info{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.info-prefix{
  color: #b59da1;
}
.info-title{
  margin-top: 10rem;
  margin-bottom: 40rem;
  font-size: 35rem;
  font-family: "Aleo","sans-serif";
}
.info-prices{
  font-size: 60rem;
  margin-bottom: 30rem;
  font-family: "Aleo","sans-serif";
}
.carousel-morph{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.morph-div{
  display: flex;
  flex-wrap: wrap;
}
.morph-button{
  height: 70rem;
  padding: 0  30rem;
  text-align: center;
  line-height: 70rem;
  font-size: 23rem;
  margin-top: 15rem;
  margin-right: 20rem;
  border-radius: 10rem;
  user-select: none;
  cursor: pointer;
}
.morph-button-wxz{
  background-color: #f1eded;
  color: #3A3A3E;
}
.morph-button-wxz:hover{
  background-color: #f6f8f4;
}
.morph-button-wxz:active{
  background-color: #e4dada;
}
.morph-button-xz{
  background-color: #3A3A3E;
  color: white;
}
.carousel-bottom{
  margin-top: 50rem;
}
.stock-p{
  display: flex;
  align-items: center;
  border-radius: 10rem;
  overflow: hidden;
}
.stock-state{
  display: flex;
  align-items: center;
  height: 70rem;
  width: 170rem;
  background-color: #b59da1;
  padding: 0 25rem;
}
.stock-state div{
  margin-right: 15rem;
  width: 25rem;
  height: 25rem;
  background-color: white;
  border-radius: 50%;
}
.stock-state span{
  color: white;
}
.stock-sp{
  height: 70rem;
  line-height: 70rem;
  flex: 1;
  text-align: center;
  background-color: #f1eded;
  user-select: none;
  font-family: "Aleo", "sans-serif";
  cursor: pointer;
}
.stock-sp:hover{
  background-color: #f6f8f4;
}
.stock-sp:active{
  background-color: #edede6;
}
.buy-button{
  margin-top: 20rem;
  background-color: rgb(84,51,235);
  text-align: center;
  height: 80rem;
  line-height: 80rem;
  font-size: 40rem;
  color: white;
  border-radius: 10rem;
  font-family: "Aleo","sans-serif";
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
}
.buy-button span{
  font-size: 45rem;
  font-weight: bold;
}
.buy-button:hover{
  background-color: rgb(122, 101, 236);

}
.buy-button:active{
  background-color: rgb(99, 81, 191);
}
.img-aj{
  margin-top: 100rem;
}
.img-aj div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-aj img{
  width: 600rem;
}
.description{
  background-color: #edeae4;
  padding: 30rem;
  border-radius: 20rem;
}
.description span{
  width: 600rem;
  font-size: 30rem;
}
</style>