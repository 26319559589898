<script>
import SvgIcon from "@/assets/icon/index.vue";
import info from "@/assets/img/info";

export default {
  name: "FAQsMain",
  components: {SvgIcon},
  data() {
    return {
      info,
      img: "",
      title: "",
      faqname: "",
      faqflile: ""
    }
  },
  watch: {
    title(newVal){
      this.info.forEach(item=>{
        if (item.title === newVal){
          this.img = item.img;
          this.faqname = item.faqname;
          this.faqflile = item.faqflile;
        }
      })
    }
  },
  created() {
    this.title = info[2].title;
  }
}
</script>

<template>
  <div class="faq-main">
    <div class="info-download">
      <div style="margin: 30rem 0;">
        <span style="font-size: 35rem;font-family: 'Aleo', 'sans-serif'">Can download the required help files</span>
      </div>
      <div class="info-div">
        <img class="img-download" :src="img">
        <select id="skills" name="skills" v-model="title" class="download-select">
          <option v-for="(item,index) in info" :value="item.title" :key="index">{{item.title}}</option>
        </select>
      </div>
      <div class="download-div">
        <svg-icon icon-class="word" style="font-size: 60rem;color: #8989dc"></svg-icon>
        <span class="span-download">{{ faqname }}</span>
      </div>
      <a :href="faqflile" :download="faqname">
        <div class="download-button">Download</div>
      </a>
    </div>
  </div>
</template>

<style scoped>
.faq-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50rem;
}

.info-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20rem;
}

.info-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-div span {
  width: 250rem;
}

.img-download {
  width: 250rem;
  margin-bottom: 30rem;
}
.download-select{
  width: 200rem;
  height: 70rem;
  margin-top: 20rem;
  border: #b59da1 2rem solid;
  border-radius: 5rem;
  outline:none;
}

.span-download {
  margin: 0 20rem;
}

.download-div {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 30rem 0;
}

.download-div span {
  width: 300rem;
}

.download-button {
  width: 200rem;
  height: 50rem;
  color: white;
  line-height: 50rem;
  text-align: center;
  border-radius: 5rem;
  background-color: #3A3A3E;
}
</style>